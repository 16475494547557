import React from 'react';
import Excel from '../components/excel/Excel';
import Navbar from '../components/navbar/NavbarAdmin';
import '../sass/Excel.sass'

function CargaExcel() {
  return (
    <>
      <aside style={{marginTop:'3%', marginLeft:'5%'}}>
        <Navbar id="navbarsito"/>
      </aside>
      
      <div className='primer-torneo'>
        <div style={{marginLeft:'0%', marginBottom:'3%'}}>
          <a>
            <img
                  
                  src={require("../images/logo_starplay.png")}
                  width="80%"
  
                  className="d-inline-block align-top"
                  alt="Logo de eliminatorias StarPlay"
              />
          </a>
        </div>
      </div>
      <div className='tabla-excel'>
        <Excel/>
      </div>
    </>
  );
}

export default CargaExcel;