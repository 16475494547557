import React, { useState, useEffect } from 'react';
import Banner from '../components/banner/Banner';
import PdfRules from '../components/banner/PdfRules';
import Navbar from '../components/navbar/NavbarAdmin';

import {Box, Button, Dialog, DialogActions,
        DialogContent, DialogContentText, DialogTitle,
        FormControl, FormControlLabel, InputLabel,
        MenuItem, Select, Switch, Input, 
        TextField} from '@mui/material'

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";

import "../sass/CargaImagenes.sass"
import API from "../services/config"

function CargaPremios() {

    const [dataSelect, setDataSelect] = useState([])

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClean = async () => {
      
      const response = await axios.delete(API.baseURL + "api/delete/premio/" + idRemoveValue);

      if (response.data.code === 0) {
        toast.success(`Se borro correctamente el texto del premio`);
        window.location.reload();
      } else {
        toast.error(`No se pudo borrar el texto del premio`);
      }
    };
  
    const handleSelectChange = (event) => {
      setIdRemoveValue(
        event.target.value,
      );
    };
    //
    const [tituloValue, setTituloValue] = useState("");
    const [descripcionValue, setDescripcion] = useState("");
    const [premio1Value, setPremio1Value] = useState("");
    const [premio2Value, setPremio2Value] = useState("");
    const [premio3Value, setPremio3Value] = useState("");
    const [idValue, setIdValue] = useState('');
    const [idRemoveValue, setIdRemoveValue] = useState('');

    const [disableTorneo1, setDisableTorneo1] = useState(true);
    const [disableTorneo2, setDisableTorneo2] = useState(true);

    const handlePremioTextUpload = async () => {
      try {
        // Assuming you have imageData, imageName, and imageContentType in your component's state or data
        const response = await axios.post(API.baseURL + 'api/create/premio', {
          id: idValue,
          titulo: tituloValue, // Replace with the actual image name
          descripcion: descripcionValue, // Replace with the actual Base64-encoded image data
          valoresPremios: [{premio: premio1Value},{premio: premio2Value},{premio: premio3Value}], // Replace with the actual MIME type
        });
    
        if (response.data.code === 0) {
          // Image was created successfully
          //console.log('Pdf created successfully');
          toast.success(`Se monto correctamente el pdf`);
          window.location.reload();
          // Add any additional logic here, e.g., redirecting the user or displaying a success message
        } else {
          // Handle any error cases here
          //console.error('Pdf creation failed:', response.data.message);
          toast.error(`No se puedo montar el pdf`);
        }
      } catch (error) {
        toast.error(`No se puedo montar el pdf`);
      }
    };

    let cargarDatosSelect = async () =>{
      Swal.fire({ title: "Cargando", allowOutsideClick: false });
      Swal.showLoading();
      let data = await axios.get(API.baseURL + "api/get/premio/all").then(e =>{
        if(e.data.premios.length == 0){
          setDataSelect([{hola:'hola'}])
          Swal.close();
        }else{
          setDataSelect(e.data.premios)
          e.data.premios.map(data => {
            if(data.id == 0){
              setDisableTorneo1(false)
            }
            if(data.id == 1){
              setDisableTorneo2(false)
            }
          })
          Swal.close();
        }
      });
    }

    useEffect(() => {
      cargarDatosSelect()
    }, [])

    const agregarDisabled = () => {
      if(tituloValue == '' || descripcionValue == '' || premio1Value == '' || premio2Value == '' || premio3Value == '' || idValue == ''){
        return true
      }else{return false}
    }

    const eliminarDisabled = () => {
      if((!disableTorneo1 && disableTorneo2) || (disableTorneo1 && !disableTorneo2) || (!disableTorneo1 && !disableTorneo2)){
        return false
      }else{
        return true
      }
    }

    const handleIdChange = (event) => {
      setIdValue(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
      );
    };

  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      
      <aside style={{marginTop:'3%', marginLeft:'5%'}}>
        <Navbar id="navbarsito"/>
      </aside>
      
      <div className='primer-torneo'>
        <div style={{marginLeft:'0%', marginBottom:'3%'}}>
          <a>
            <img
                  
                  src={require("../images/logo_starplay.png")}
                  width="80%"
  
                  className="d-inline-block align-top"
                  alt="Logo de eliminatorias StarPlay"
              />
          </a>
        </div>
      </div>

      <div className='tabla-excel'>

        <div className="primer-torneo">
            <Box
                    noValidate
                    component="form"
                    style={{ borderRadius:'5px', backgroundColor:'#eceef24a',
                            width:'650px',height:'700px', marginBottom:'2rem'}}
                >

              <FormControl sx={{ mt: 2, minWidth: 140 }}>
                
                <div style={{width:'120px', marginBottom: '0rem', marginLeft:'170%', display:'flex', alignItems:'center' }}>
                  <InputLabel style={{marginLeft:'170%'}} htmlFor="min-width">Torneo</InputLabel>
                  <Select
                      style={{width:'100%'}}
                      autoFocus
                      value={idValue}
                      onChange={handleIdChange}
                      label="PDF"
                      inputProps={{
                          name: 'min-width',
                          id: 'min-width',
                      }}
                      >
                      
                      <MenuItem value='0' disabled={!disableTorneo1}> Torneo 1 </MenuItem>
                      <MenuItem value='1' disabled={!disableTorneo2}> Torneo 2 </MenuItem>
                      
                  </Select>
                </div>

              </FormControl>

                <div style={{width:'350px', marginTop: "3rem", marginLeft:'20%'}}>
                    <TextField
                        onChange={(e) => setTituloValue(e.target.value)}
                        style={{
                        color: "#1b3766",
                        fontSize: "bold",
                        width: "100%",
                        }}
                        label='Titulo'
                    />
                </div>

                <div style={{width:'350px', marginTop: "3rem", marginLeft:'20%'}}>
                    <TextField
                        onChange={(e) => setDescripcion(e.target.value)}
                        style={{
                        color: "#1b3766",
                        fontSize: "bold",
                        width: "100%",
                        }}
                        label='Descripción'
                    />
                </div>

                <div style={{width:'350px', marginTop: "3rem", marginLeft:'20%'}}>
                    <TextField
                        onChange={(e) => setPremio1Value(e.target.value)}
                        style={{
                        color: "#1b3766",
                        fontSize: "bold",
                        width: "100%",
                        }}
                        label='Premio 1er puesto'
                    />
                </div>

                <div style={{width:'350px', marginTop: "3rem", marginLeft:'20%'}}>
                    <TextField
                        onChange={(e) => setPremio2Value(e.target.value)}
                        style={{
                        color: "#1b3766",
                        fontSize: "bold",
                        width: "100%",
                        }}
                        label='Premio 2do puesto'
                    />
                </div>

                <div style={{width:'350px', marginTop: "3rem", marginLeft:'20%'}}>
                    <TextField
                        onChange={(e) => setPremio3Value(e.target.value)}
                        style={{
                        color: "#1b3766",
                        fontSize: "bold",
                        width: "100%",
                        }}
                        label='Premio 3er puesto'
                    />
                </div>

                <div className='btn' style={{ marginTop: "2rem", marginLeft:'0%' }}>
                <Button
                    className='button_upload'
                    variant="contained"
                    onClick={handlePremioTextUpload}
                    disabled = {agregarDisabled()}
                    >
                    Agregar texto Premio
                </Button>
                <Button 
                disabled={eliminarDisabled()} 
                className='button_upload' 
                variant="outlined" 
                onClick={handleClickOpen}
                >
                    Eliminar texto Premio
                </Button>
                </div>
            
            </Box>

            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Eliminar Premios</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Escoge por el torneo de los premios que quieres eliminar
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                    }}
                >
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                    <InputLabel htmlFor="max-width">Premios</InputLabel>
                    <Select
                        autoFocus
                        value={idRemoveValue}
                        onChange={handleSelectChange}
                        label="PDFs"
                        inputProps={{
                        name: 'max-width',
                        id: 'max-width',
                        }}
                    >
                        <MenuItem value='0' disabled={disableTorneo1}> Torneo 1 </MenuItem>
                        <MenuItem value='1' disabled={disableTorneo2}> Torneo 2 </MenuItem>
                        {/*
                        dataSelect.length == 1 
                        ?dataSelect[0].pdfId == 0 
                            ?<MenuItem value='0'> Torneo 1 </MenuItem>
                            :dataSelect[0].pdfId == 1
                                ?<MenuItem value='1'> Torneo 2 </MenuItem>
                                :<></>
                        :dataSelect.length == 2 
                            ?
                            <>
                                <MenuItem value='0'> Torneo 1 </MenuItem>
                                <MenuItem value='1'> Torneo 2 </MenuItem>
                            </>
                            :<></>
                        */}
                        
                        
                    </Select>
                    
                    </FormControl>
                </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClean}>Eliminar</Button>
                <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        
        </div>

      </div>

    </>
  );
}

export default CargaPremios;