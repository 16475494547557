import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SideBarDataAdmin } from './SideBarDataAdmin';
import './Navbar.css';
import { IconContext } from 'react-icons';

function NavbarAdmin() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div>
        <IconContext.Provider value={{ color: '#021d49' }}>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
              <li className='navbar-toggle'>
                <Link to='#' className='menu-bars'>
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SideBarDataAdmin.map((item, index) => {
                return (
                  <li key={index} className={item.cName} >
                    <Link to={item.path} value={{ color: '#021d49', }}>
                    {/*<img
                        
                        width="100%"
        
                        className="d-inline-block align-top"
                        alt="Equipo vs Equipo"
                    />*/}
                      <div value={{ color: '#021d49' }}>
                        <span>{item.icon}</span> 
                        <span>{item.title}</span>
                      </div>
                      
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    </>
  );
}

export default NavbarAdmin;