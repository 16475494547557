import React, { useState } from "react";
import * as XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

import "../../sass/Excel.sass"
import API from "../../services/config"

function Excel() {

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [parrafo, setParrafo] = useState("");
  const [parrafo2, setParrafo2] = useState("");

  const postExcel = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let promises = [];
    const sizeOfReq = 100;
    await axios.delete(API.baseURL + "api/delete/primer-torneo");
    await axios.delete(API.baseURL + "api/delete/segundo-torneo");
    for (let i = 0; i < data.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                API.baseURL + "api/create/primer",
                data.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }
    for (let i = 0; i < data2.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                API.baseURL + "api/create/segundo",
                data2.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
            Swal.close();
            toast.error(`Se ha montado el excel exitosamente`);
          }
        })
      );
    }
    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);
  };

  const addExcel = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let promises = [];
    const sizeOfReq = 100;
    for (let i = 0; i < data.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                API.baseURL + "api/create/primer",
                data.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }
    for (let i = 0; i < data2.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                API.baseURL + "api/create/segundo",
                data2.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
            Swal.close();
            toast.error(`Se ha montado el excel exitosamente`);
          }
        })
      );
    }
    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);
  };

  const postParrafo = async () => {
    console.log(parrafo);
    let boolSubtitulo = await axios.get(API.baseURL + "api/check/subtitulos/0");
    console.log(boolSubtitulo.data.existe);
    if (boolSubtitulo.data.existe) {
      let auxSubtitulo = await axios.get(API.baseURL + "api/get/subtitulos/id/0");
      let subtituloPatch = {
        id: 0,
        primerParrafo: parrafo,
        segundoParrafo: auxSubtitulo.data.subtitulos.segundoParrafo,
      };
      await axios.post(
        API.baseURL + "api/update/primer-subtitulos",
        subtituloPatch
      );
    } else {
      let subtituloPatch = {
        id: 0,
        primerParrafo: parrafo,
        segundoParrafo: "",
      };
      await axios.post(API.baseURL + "api/crear/subtitulos", subtituloPatch);
    }

    toast.success(`Titulo de la pagina torneo 1 ACTUALIZADO`);
  };

  const postParrafo2 = async () => {
    console.log(parrafo2);
    if (parrafo2 === "") {
      console.log("parrafo2 vacio");
    }
    let boolSubtitulo = await axios.get(API.baseURL + "api/check/subtitulos/0");
    console.log(boolSubtitulo.data.existe);
    if (boolSubtitulo.data.existe) {
      let auxSubtitulo = await axios.get(API.baseURL + "api/get/subtitulos/id/0");
      let subtituloPatch = {
        id: 0,
        primerParrafo: auxSubtitulo.data.subtitulos.primerParrafo,
        segundoParrafo: parrafo2,
      };
      console.log(subtituloPatch);
      await axios.post(
        API.baseURL + "api/update/segunda-subtitulos",
        subtituloPatch
      );
    } else {
      let subtituloPatch = {
        id: 0,
        primerParrafo: "",
        segundoParrafo: parrafo2,
      };
      await axios.post(API.baseURL + "api/crear/subtitulos", subtituloPatch);
    }
    toast.success(`Titulo de la pagina torneo 2 ACTUALIZADO`);
  };

  const handFile = async (e) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });
    const jsonData2 = XLSX.utils.sheet_to_json(worksheet2, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let dataTable2 = [];
    let header;
    let header2;
    let index = 0;
    let index2 = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });
    jsonData2.forEach((json) => {
      if (index2 < 1) {
        index2++;
        header2 = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable2.push(bodyColumn);
      }
    });

    setData(dataTable);
    setData2(dataTable2);
    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
  };

  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      <div className="primer-torneo">
        <div style={{ marginTop: "3rem" }}>
          <Input
            type="file"
            onChange={(e) => handFile(e)}
            style={{
              color: "#1b3766",
              fontSize: "bold",
            }}
          />
        </div>

        <div style={{ paddingLeft: "8rem", marginTop: "3rem" }}>
          <Button
            style={{
              color:'#FFF',
              backgroundColor:'#021d49'
            }}
            className='button_excel'
            variant="contained"
            onClick={postExcel}
          >
            Borrar y montar excel
          </Button>
        </div>
      </div>

      <div className="primer-torneo">
        <div style={{ marginTop: "3rem" }}>
          <Input
            type="file"
            onChange={(e) => handFile(e)}
            style={{
              color: "#1b3766",
              fontSize: "bold",
            }}
          />
        </div>

        <div style={{ paddingLeft: "8rem", marginTop: "3rem" }}>
          <Button
            style={{
              color:'#FFF',
              backgroundColor:'#021d49'
            }}
            className='button_excel'
            variant="contained"
            onClick={addExcel}
          >
            Añadir datos {'( excel )'}
          </Button>
        </div>
      </div>

      <div className="primer-torneo">
        <div style={{ marginTop: "3rem", marginRight: "3rem" }}>
          <Input
            onChange={(e) => setParrafo(e.target.value)}
            style={{
              color: "#1b3766",
              fontSize: "bold",
              width: "200%",
            }}
          />
        </div>

        <div style={{ paddingLeft: "8rem", marginTop: "3rem" }}>
          <Button
            style={{
              color:'#FFF',
              backgroundColor:'#021d49'
            }}
            variant="contained"
            onClick={postParrafo}
            className='button_excel'
          >
            Actualizar Subtitulo Primera Pagina
          </Button>
        </div>
      </div>

      <div className="primer-torneo">
        <div style={{ marginTop: "3rem", marginRight: "3rem" }}>
          <Input
            onChange={(e) => setParrafo2(e.target.value)}
            style={{
              color: "#1b3766",
              fontSize: "bold",
              width: "200%",
            }}
          />
        </div>

        <div style={{ paddingLeft: "8rem", marginTop: "3rem" }}>
          <Button
            style={{
              color:'#FFF',
              backgroundColor:'#021d49'
            }}
            variant="contained"
            onClick={postParrafo2}
            className='button_excel'
          >
            Actualizar Subtitulo Segunda Pagina
          </Button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.5rem",
          paddingTop: "0rem",
        }}
      ></div>
    </>
  );
}

export default Excel;
