import React, { useState, useEffect } from 'react';
import Banner from '../components/banner/Banner';
import PdfRules from '../components/banner/PdfRules';
import Navbar from '../components/navbar/NavbarAdmin';

import {Box, Button, Dialog, DialogActions,
        DialogContent, DialogContentText, DialogTitle,
        FormControl, FormControlLabel, InputLabel,
        MenuItem, Select, Switch, Input, 
        TextField} from '@mui/material'

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";

import {MdCloudUpload} from 'react-icons/md';

import "../sass/CargaImagenes.sass"
import API from "../services/config"

function CargaImagenes() {

    const [dataSelect, setDataSelect] = useState([])
    const [chargeSuccesful, setChargeSuccesful] = useState(false)

    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [imageNameValue, setImageNameValue] = useState('');

    const [renderImage, setRenderImage] = useState('');
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClean = async () => {
      Swal.fire({ title: "Cargando", allowOutsideClick: false });
      Swal.showLoading();
      await axios.delete(API.baseURL + "api/delete/imagen/" + imageNameValue).then(e =>{
        Swal.close();
      }).then(() => {
        toast.success(`Se elimino la imagen exitosamente`);
        window.location.reload();
      });
    };
  
    const handleMaxWidthChange = (event) => {
      setImageNameValue(
        event.target.value,
      );
    };

    const [imgCache,setImgCache] = useState('')
    const [fileType,setFileType] = useState('')
    const [imageName, setImageName] = useState('');
    const [allImage, setAllImage] = useState([]);

    const postParrafo = () => {
      toast.success(`Titulo de la pagina torneo 1 ACTUALIZADO`);
    };

    const handleUpload = async (e) => {
      e.preventDefault()
      
      if(imgCache){
        if(imageName){
          await axios.post(
            API.baseURL + "api/create/imagen",
            {
              imageName: imageName,
              imageData: imgCache,
              imageContentType: fileType
            }
          ).then(() => {
            toast.success(`Se cargo la imagen correctamente`);
            window.location.reload();
          })
          
        }else{toast.error('No has colocado un nombre de imagen')}
      }
      else{
        if(imageName){
          toast.error('No has colocado una imagen')
        }else{toast.error('No has colocado ni la imagen ni el nombre de la imagen')}
        
      }
      
    }

    //hacer que la imagen que montes en cache se muestre
    //const [imgCache,setImgCache] = useState('')

    const imagebase64 = async (file) => {
      setFileType(file.type)
      const reader = new FileReader()
      await reader.readAsDataURL(file)
      const data = new Promise((resolve,reject) => {
        reader.onload = () => resolve(reader.result)
        reader.onerror = (err) => reject(err)
      })
      return data
    }

    const handleUploadImage = async (e) => {
      const file = e.target.files[0]
      const image = await imagebase64(file)
      setImgCache(image)
    }

    let cargarDatosSelect = async () =>{
      Swal.fire({ title: "Cargando", allowOutsideClick: false });
      Swal.showLoading();
      let data = await axios.get(API.baseURL + "api/get/imagen/all").then(e =>{
        if(e.data.imagen.length == 0){
          //setDataSelect([{hola:'flag'}])
          setChargeSuccesful(false)
          Swal.close();
        }else{
          setDataSelect(e.data.imagen)
          setChargeSuccesful(true)
          Swal.close();
        }
      });
    }

    useEffect(() => {
      cargarDatosSelect()
    }, [])

    const agregarDisabled = () => {
      if(imageName == ''){
        return true
      }
      else if(imgCache == ''){
        return true
      }
      else{return false}
    }

    const eliminarDisabled = () => {
      if(!chargeSuccesful){
        return true
      }else{
        return false
      }
    }

  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      
      <aside style={{marginTop:'3%', marginLeft:'5%'}}>
        <Navbar id="navbarsito"/>
      </aside>
      
      <div className='primer-torneo'>
        <div style={{marginLeft:'0%', marginBottom:'3%'}}>
          <a>
            <img
                  
                  src={require("../images/logo_starplay.png")}
                  width="80%"
  
                  className="d-inline-block align-top"
                  alt="Logo de eliminatorias StarPlay"
              />
          </a>
        </div>
      </div>
      
      <div className='tabla-excel'>
        
        <div className="primer-torneo">
          <div style={{ marginBottom: '1rem', display:'flex', alignItems:'center' }}>
            <TextField
              className='image_name'
              fullWidth 
              onChange={(e) => setImageName(e.target.value)}
              
              label='Nombre de imagen'
            />
          </div>
        </div>
        
        <div className='imageContainer'>
          <form>
            <label>
              <div className='uploadBox'>
                <input type='file' id='uploadImage' onChange={handleUploadImage} className='input_file_image'></input>
                { imgCache ? <img src={imgCache}/> : <MdCloudUpload/>}
              </div>
            </label>
            <div className='btn'>
              <Button disabled={agregarDisabled()} className='button_upload' variant="outlined" onClick={handleUpload}>Montar imagen</Button>
              <Button disabled={eliminarDisabled()} className='button_upload' variant="outlined" onClick={handleClickOpen}>
                Eliminar imagen
              </Button>
            </div>
          </form>
        </div>

        
        
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Eliminar imagenes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Escoge por el nombre de la imagen la que quieres eliminar
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel htmlFor="max-width">imagenes</InputLabel>
                <Select
                  autoFocus
                  value={imageNameValue}
                  onChange={handleMaxWidthChange}
                  label="imagenes"
                  inputProps={{
                    name: 'max-width',
                    id: 'max-width',
                  }}
                >
                  {dataSelect.map((menuValue) => (
                    <MenuItem value={menuValue.imageName}> {menuValue.imageName} </MenuItem>
                  ))}
                </Select>
                
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClean}>Eliminar</Button>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      
      </div>
      
      <div style={{marginTop:'6rem'}}>
        {dataSelect.map((imageValue) => (
          <img src={imageValue.imageData} width='400px' height='472px'/>
        ))}
      </div>

    </>
  );
}

export default CargaImagenes;