import React, { useState, useEffect } from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import producto1 from  '../images/prueba/Banner_pequeno.png'
import producto2 from  '../images/Logo Fullcolor only the lion.png'
import producto3 from  '../images/Logo Fullcolor only the lion.png'
import producto4 from  '../images/Logo Fullcolor only the lion.png'
import Navbar from '../components/navbar/Navbar';

import axios from "axios";
import Swal from "sweetalert2";

import "../sass/Inicio.sass"
import API from "../services/config"

function Inicio() {
  window.sessionStorage.setItem("one", "true");
  window.sessionStorage.setItem("two", "true");
  window.sessionStorage.setItem("four", "true");
  window.sessionStorage.setItem("three", "false");
  window.sessionStorage.setItem("text1", "");

  const [render_coso, setRender_coso] = useState(<></>)
  const [srcBanner, setSrcBanner] = useState('')

  /*const renderprueba = () => {
    const promociones = [{url:producto1,nombre:'Premio 1'},
                        {url:producto2,nombre:'Premio 2'},
                        {url:producto3,nombre:'Premio 3'},
                        {url:producto4,nombre:'Premio 4'}]
    return (
      <>
        <Box sx={{ flexGrow: 1 }} >
          <Grid style={{marginLeft:'-5%'}}
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 1, sm: 1, md: 12 }}
          >
            {promociones.map(promocion => (
              <Grid  
              item xs={6} sm={6} md={3} key={promocion.nombre}>
                <div>
                <img
                    src={promocion.url}
                    height="30%"
                    width="25%"

                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
                  <h4 style={{marginLeft:'25%'}}>{promocion.nombre}</h4>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>

      </>
    )
  }
  
  
                  <div className='logo_main_area'>
                    <a href="/primer-torneo" >
                      <img
                            height="80%"
                            width="100%"
                            src={require("../images/logo_starplay.png")}
                            className="d-inline-block align-top"
                            alt="Logo de eliminatorias StarPlay"
                        />
                    </a>
                  </div>
  */

  const handleClick = (e) => {
    e.preventDefault();
    console.log('The link was clicked.');
  };

  const renderImagenes = (data_images) => {
    console.log(data_images)
    console.log(data_images == 0)
    let arrayImages = []
    if(data_images > 3){
      arrayImages.push(data_images[0])
      arrayImages.push(data_images[1])
      arrayImages.push(data_images[2])
    }else{
      arrayImages = data_images
    }
    setRender_coso(
        <>
            {data_images.length == 0 ?
            <></>
            :data_images.length == 1 ?
            <div style={{border:'2px solid black',marginLeft:'1%', width:'400px', height:'472px'}}>
              <img
                width='100%'
                height='100%'
                src={data_images[0].imageData}
                alt="Equipo vs Equipo"
              />
            </div>
            
            :
            arrayImages.map(image => {
              if(data_images.length)
              return(
              <div style={{marginLeft:'1%', width:'400px', height:'472px'}}>
                <img
                  className='imagenes_banner'
                  src={image.imageData}
                  alt="Equipo vs Equipo"
                />
              </div>
              )
            })
            }
        </>
    )
  }

  const cargarImagenes = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(API.baseURL + "api/get/imagen/all").then(e =>{
      if(e.data.imagen.length > 0){
          console.log(e.data.imagen)
          renderImagenes(e.data.imagen)
      }
      Swal.close();
    });

    let data_imagen = await axios.get(API.baseURL + "api/get/banner/all").then(e => {
      if(e.data.banner.length > 0){
        setSrcBanner(e.data.banner[0].bannerData)
      }
      Swal.close();
    })

  }

  useEffect(() => {
    cargarImagenes()
  }, [])

  return (
    <>
        <body className='body_inicio'>
          <div className='container'>
            <div className='content_area group'>

              <div className='main_area'>
                  
                  <grid container spacing={0} className='navbar' style={{marginTop:'2%'}}>

                    
                    <div className='div_upper_logo'>
                      <a href="/primer-torneo">
                        <img
                              
                              src={require("../images/logo_starplay.png")}
                              width="80%"
              
                              className="d-inline-block align-top"
                              alt="Logo de eliminatorias StarPlay"
                          />
                      </a>
                    </div>
                    
                  </grid>
                  
                  {/*<h4>
                  Texto sobre promociones o el tipo de torneo que se quiere visualizar
                  {
                  //Apuesta, acumula puntos y tendrás la oportunidad de ganar ENTRADAS DOBLES con todo incluido al partido y muchos premios más...
                  }
                </h4>*/}
                  {/*<img
                     
                      width="100%"
                      alt="Equipo vs Equipo"
                  />
                  <h4 style={{marginLeft:'20%'}}>
                    y muchos premios más...
                  </h4>*/}
                  
                  <div className='imagenes_promocion'>
                    
                    {/*renderprueba()*/}
                    {srcBanner && (
                      <img
                        src={srcBanner}
                        width="100%"
                        alt="Banner"
                    />
                    )}

                  </div>

                  <div>
                    <a href='/primer-torneo'>
                      <Button className='inicio_button'> 
                        Clic aquí para comenzar
                      </Button>
                    </a>
                  </div>

                  <div className='promotion_image'>
                    <grid container className='grid_productos'>
                      {render_coso}
                      
                    </grid>
                  </div>
                  
                  <div className='relleno'>

                  </div>
                  
              </div>
            </div>
          </div>
        </body>
        
    </>
  );
}

export default Inicio;