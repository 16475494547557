import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../services/config"

import {Box, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    FormControl, InputLabel,MenuItem, 
    Select, Input, TextField} from '@mui/material'

function PdfRules() {

  const [dataSelect, setDataSelect] = useState([])
  const [pdfName, setPdfName] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [pdfContentType, setPdfContentType] = useState("");
  const [pdfIdValue, setPdfIdValue] = useState('');
  const [open, setOpen] = useState(false);
  const [imageNameValue, setImageNameValue] = useState('');
  const [disableTorneo1, setDisableTorneo1] = useState(true);
  const [disableTorneo2, setDisableTorneo2] = useState(true);

  let cargarDatosSelect = async () =>{
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(API.baseURL + "api/get/pdf/all").then(e =>{
      if(e.data.pdfs.length == 0){
        setDataSelect([{hola:'hola'}])
        Swal.close();
      }else{
        setDataSelect(e.data.pdfs)
        e.data.pdfs.map((data) => {
          console.log(data)
          if(data.pdfId == 0){
              setDisableTorneo1(false)
          }
          if(data.pdfId == 1){
              setDisableTorneo2(false)
          }
      })
        Swal.close();
      }
    });
  }

  useEffect(() => {
    cargarDatosSelect()
  }, [])
  
  // Assuming you have an input element with type="file" in your component
  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Pdf = event.target.result;
      setPdfData(base64Pdf)
      // Now, you can set base64Image as the imageData in your state or component's data

      setPdfContentType(file.type)
    };
  
    reader.readAsDataURL(file);
  };

  const handlePdfUpload = async () => {
    try {
      // Assuming you have imageData, imageName, and imageContentType in your component's state or data
      const response = await axios.post(API.baseURL + 'api/create/pdf', {
        pdfId: pdfIdValue,
        pdfName: pdfName, // Replace with the actual image name
        pdfData: pdfData, // Replace with the actual Base64-encoded image data
        pdfContentType: pdfContentType, // Replace with the actual MIME type
      });
  
      if (response.data.code === 0) {
        // Image was created successfully
        //console.log('Pdf created successfully');
        toast.success(`Se monto correctamente el pdf`);
        window.location.reload();
        // Add any additional logic here, e.g., redirecting the user or displaying a success message
      } else {
        // Handle any error cases here
        //console.error('Pdf creation failed:', response.data.message);
        toast.error(`No se puedo montar el pdf`);
      }
    } catch (error) {
      toast.error(`No se puedo montar el pdf`);
    }
  };

  const handleIdChange = (event) => {
    setPdfIdValue(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleMaxWidthChange = (event) => {
    setImageNameValue(
      event.target.value,
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClean = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    await axios.delete(API.baseURL + "api/delete/pdf/" + imageNameValue).then(e =>{
      Swal.close();
      toast.success(`Se elimino correctamente el pdf`);
      window.location.reload();
    });
  };

  const agregarDisabled = () => {
    if(pdfName == ''){
      return true
    }
    else if(pdfIdValue == ''){
      return true
    }
    else if(pdfData == ''){
      return true
    }
    else{return false}
  }

  const eliminarDisabled = () => {
    if((!disableTorneo1 && disableTorneo2) || (disableTorneo1 && !disableTorneo2) || (!disableTorneo1 && !disableTorneo2)){
      return false
    }else{
      return true
    }
  }

//Que las imagenes cargadas se carguen para el carrusel
//Aclarar cual imagen se debe agarrar para el banner largo
//Colores y estilos de StarPlay
//Hacer las plantillas de los premios

//Inicio
//Torneos
//cargar-imagenes
  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      <div className="primer-torneo">
        <Box
                noValidate
                component="form"
                style={{ borderRadius:'5px', backgroundColor:'#eceef24a',
                        width:'500px',height:'400px'}}
            >
            <FormControl sx={{ mt: 2, minWidth: 140 }}>
              
              <div style={{width:'120px', marginBottom: '0rem', marginLeft:'130%', display:'flex', alignItems:'center' }}>
                <InputLabel style={{marginLeft:'130%'}} htmlFor="min-width">PDF</InputLabel>
                <Select
                    style={{width:'100%'}}
                    autoFocus
                    value={pdfIdValue}
                    onChange={handleIdChange}
                    label="PDF"
                    inputProps={{
                        name: 'min-width',
                        id: 'min-width',
                    }}
                    >
                    <MenuItem value='0' disabled={!disableTorneo1}> Torneo 1 </MenuItem>
                    <MenuItem value='1' disabled={!disableTorneo2}> Torneo 2 </MenuItem>
                </Select>
              </div>

            </FormControl>

            <div style={{width:'350px', marginTop: "3rem", marginLeft:'15%'}}>
              <TextField
                  onChange={(e) => setPdfName(e.target.value)}
                  style={{
                  color: "#1b3766",
                  fontSize: "bold",
                  width: "100%",
                  }}
                  label='Nombre del PDF'
              />
            </div>

            

            <div style={{width:'350px', marginTop: "3rem",marginLeft:'15%' }}>
              <Input
                type="file"
                onChange={(e) => handlePdfChange(e)}
                style={{
                  color: "#1b3766",
                  fontSize: "bold",
                  width: "100%",
                }}
              />
            </div>

            <div className='btn' style={{ marginTop: "3rem", marginLeft:'0%' }}>
              <Button
                  className='button_upload'
                  variant="contained"
                  onClick={handlePdfUpload}
                  disabled = {agregarDisabled()}
                >
                  Agregar PDF
              </Button>
              <Button disabled={eliminarDisabled()} className='button_upload' variant="outlined" onClick={handleClickOpen}>
                Eliminar PDF
              </Button>
            </div>
          
        </Box>
        
      </div>

      
      
      <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Eliminar PDF</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Escoge por el torneo el pdf que quieres eliminar
            </DialogContentText>
            <Box
                noValidate
                component="form"
                sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
                }}
            >
                <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel htmlFor="max-width">PDFs</InputLabel>
                <Select
                    autoFocus
                    value={imageNameValue}
                    onChange={handleMaxWidthChange}
                    label="PDFs"
                    inputProps={{
                    name: 'max-width',
                    id: 'max-width',
                    }}
                >
                    <MenuItem value='0' disabled={disableTorneo1}> Torneo 1 </MenuItem>
                    <MenuItem value='1' disabled={disableTorneo2}> Torneo 2 </MenuItem>
                    {/*
                    dataSelect.length == 1 
                    ?dataSelect[0].pdfId == 0 
                        ?<MenuItem value='0'> Torneo 1 </MenuItem>
                        :dataSelect[0].pdfId == 1
                            ?<MenuItem value='1'> Torneo 2 </MenuItem>
                            :<></>
                    :dataSelect.length == 2 
                        ?
                        <>
                            <MenuItem value='0'> Torneo 1 </MenuItem>
                            <MenuItem value='1'> Torneo 2 </MenuItem>
                        </>
                        :<></>
                    */}
                    
                    
                </Select>
                
                </FormControl>
            </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClean}>Eliminar</Button>
            <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default PdfRules;
