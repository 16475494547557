import React from 'react';
import * as RiIcons from 'react-icons/ri';

export const SideBarDataAdmin = [
  {
    title: 'Cargar Excel',
    path: '/carga-excel',
    icon: <RiIcons.RiNumber1 />,
    cName: 'nav-text'
  },
  {
    title: 'Cargar Imagenes',
    path: '/carga-imagenes',
    icon: <RiIcons.RiNumber2 />,
    cName: 'nav-text'
  },
  ,
  {
    title: 'Cargar PDF',
    path: '/carga-pdf',
    icon: <RiIcons.RiNumber3 />,
    cName: 'nav-text'
  },
  {
    title: 'Cargar Banner',
    path: '/carga-banner',
    icon: <RiIcons.RiNumber4 />,
    cName: 'nav-text'
  },
  {
    title: 'Cargar Premios',
    path: '/carga-premios',
    icon: <RiIcons.RiNumber5 />,
    cName: 'nav-text'
  },
];